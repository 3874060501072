import { Icon } from '../icon';
import type { IconProps } from '../icon';

export function IconCircleCheckCustom(props: IconProps) {
	return (
		<Icon viewBox="0 0 60 60" {...props}>
			<path d="M7.5 30a22.5 22.5 0 1 0 45 0 22.5 22.5 0 0 0-45 0Z" fill="#E6F2F3" />
			<path
				d="m37.5 25-10 10-5-5M30 52.5a22.5 22.5 0 1 1 0-45 22.5 22.5 0 0 1 0 45Z"
				stroke="#027F88"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</Icon>
	);
}
